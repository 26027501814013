import { PrimaryButton } from "components/Inputs/Button"
import { LoginProps, withLogin } from "components/Login/withLogin"
import UpcomingEventCard from "components/LSRevamp/Events/common/UpcomingEventCard"

type Props = {
  events: Array<any>
  sectionHeading?: string
  trackingEvent?: () => void
}

function EventSection(props: Props & LoginProps) {
  const { events, handleSpotCounsellingFlow, sectionHeading, trackingEvent } =
    props

  return (
    <div className="bg-violet-150 px-4 md:px-0">
      <div className="w-full pb-7 pt-7 max-w-[1200px] md:flex md:flex-col md:justify-between md:items-center md:m-auto md:pt-[80px] md:pb-[80px] md:pl-0 md:pr-0">
        {!sectionHeading ? (
          <h2 className="text-center font-bold text-2xl md:text-[36px] mb-[32px]">
            Get a free Masterclass from high achievers
          </h2>
        ) : (
          <div className="flex flex-col gap-1 px-22">
            <p className="leading-8 text-2xl text-center font-bold md:text-[36px] md:leading-[56px]">
              {sectionHeading}
            </p>
            <p className="md:text-xl text-base leading-5 md:leading-8 text-[#424242] text-center mt-1">
              Live, virtual info sessions with India&#39;s top study abroad
              experts!
            </p>
          </div>
        )}

        <div className="flex max-w-[100vw] overflow-x-scroll hide-scroll-bar px-5 md:items-center md:justify-center">
          <div className="flex flex-nowrap overflow-y-hidden">
            {events.map((event: any, i: number) => (
              <div className="mr-20" key={i}>
                <UpcomingEventCard
                  data={event}
                  bookedEvents={[]}
                  eventPageName={{}}
                  hideCta={true}
                />
              </div>
            ))}
          </div>
        </div>

        <PrimaryButton
          ctaText="Book a Free Counselling Session"
          onClick={() => {
            if (trackingEvent) trackingEvent()
            if (handleSpotCounsellingFlow) handleSpotCounsellingFlow()
          }}
          className="font-bold md:text-font16 justify-center flex text-white py-4 px-5 mx-auto w-[306px] mt-8"
        />
      </div>
    </div>
  )
}

export default withLogin(EventSection)
