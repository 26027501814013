import React, { useState, useEffect } from 'react'
import Image from 'next/image'
import { trackEventsClick } from 'scripts/segment'
import { pageName } from 'utils/events-utils'
import { useRouter } from 'next/router'
import { formatTime } from 'utils/ielts_date_utils'
import { getTags } from 'utils/event_utils'
import moment from 'moment'
import { PrimaryButton } from 'components/Inputs/Button'

interface Props {
  data: any
  bookedEvents: any
  className?: string
  redirectFromPage?: string
  eventPageName: any
  hideCta?: boolean
}

const JOIN_NOW = 'Join Now'
const BOOK_FREE_SEAT = 'Book a free seat'
const EVENT_BOOKED = 'Event Booked'

const UpcomingEventCard = ({
  data,
  bookedEvents,
  className = '',
  redirectFromPage,
  eventPageName,
  hideCta = false,
}: Props) => {
  const [isBooked, setIsBooked] = useState(false)
  const {
    name,
    date,
    starHour,
    startMinute,
    endHour,
    endMinute,
    batchId,
    isStarted,
    event_tags_v2,
    seatsBooked,
    isOfflineEvent
  } = data
  const router = useRouter()
  const [eventTags, setEventTags] = useState<any[]>([])
  const cardCTAContent = () => {
    if (isBooked && !isStarted) return EVENT_BOOKED
    if (isStarted && !isOfflineEvent) return JOIN_NOW
    else return BOOK_FREE_SEAT
  }
  const handleClickEvent = () => {
    if (
      [BOOK_FREE_SEAT, JOIN_NOW].includes(cardCTAContent()) &&
      router.pathname === '/profile/counselling'
    ) {
      trackEventsClick(pageName.BOFU_User_Dashboard, {
        widgetName: cardCTAContent(),
        widgetFormat: 'Card',
        contentName:
          cardCTAContent() === BOOK_FREE_SEAT ? 'Book Event' : 'Join Event',
        contentFormat: 'Button',
      })
    } else if (router.pathname !== '/profile/counselling') {
      trackEventsClick(
        redirectFromPage
          ? eventPageName[redirectFromPage]
          : pageName.Event_Landing_Page,
        {
          widgetName: 'Upcoming event card',
          widgetFormat: 'Card',
          contentName: name,
          contentFormat: 'Button',
          eventId: batchId,
          tags: eventTags.join(','),
          cta: cardCTAContent(),
        }
      )
    }
    //use window.open because router.push does not refresh the full page
    window.open(`/events/${batchId}`, '_self')
    // router.push(`/events/${batchId}`)
  }
  const formatTimee = (counsellingDate: string): string => {
    const dateNew = moment(counsellingDate).format('YYYY-MM-DD HH:mm:ss')
    const startTime = moment(dateNew).format('DD MMM')
    return `${startTime}`
  }

  useEffect(() => {
    if (bookedEvents?.length > 0) {
      const booked = bookedEvents.some((obj: any) => obj?.id === batchId)
      setIsBooked(booked)
    }
  }, [data, bookedEvents])

  useEffect(() => {
    if (data) {
      const tags = getTags(event_tags_v2)
      setEventTags(tags)
    }
  }, [data])

  return (
    <div
      className={`w-[306px] cursor-pointer  rounded-[3px] m-auto mt-10 ${className} relative shadow-14 mb-4 bg-white`}
      onClick={handleClickEvent}
    >
      <div className="relative">
        {isStarted && !isOfflineEvent && (
          <span className="flex items-center text-[10px] font-bold uppercase bg-[#F43B3B] p-1 text-white w-[70px] rounded-[4px] absolute top-[-10px] left-[10px] z-10">
            <p className="w-[8px] h-[8px] bg-white rounded-[50%] mr-1" /> Live
            Now
          </span>
        )}
        {data?.thumbnail?.url && (
          <Image
            src={data?.thumbnail?.url}
            width={340}
            height={220}
            alt="event_demo_thumbnail"
            className="rounded-t-[5px]"
          />
        )}
      </div>
      <div className="h-full -mt-2">
        {eventTags?.length > 0 && (
          <div className="flex flex-wrap mt-1 ml-[10px]  pt-2">
            {eventTags?.map((tag, index) => {
              return (
                tag && (
                  <span
                    key={index}
                    className="px-2 py-1 bg-[#E8E8E8] rounded-[36px] mr-2 my-1 text-[12px] font-normal"
                  >
                    {tag}
                  </span>
                )
              )
            })}
          </div>
        )}
        <div className="px-[15px] flex flex-col">
          <div className="w-full py-2">
            <div className="flex mt-2 items-center">
              <Image
                src= {`${process.env.CDN_URL}/assets/icons/LSRevamp/calender_icon.svg`}
                width={16}
                height={16}
                alt="calender_icon"
              />
              <p className="font-normal text-[14px] text-[#1C1C1C] ml-2">
                {formatTimee(date)} |{' '}
                {formatTime(starHour, startMinute, endHour, endMinute)}
              </p>
            </div>
            <div className="flex mt-[10px] items-center">
              <Image
                src= {`${process.env.CDN_URL}/assets/icons/LSRevamp/person_icon.svg`}
                width={16}
                height={16}
                alt="person_icon"
              />
              <p className="font-normal text-[14px] text-[#1C1C1C] ml-2">
                <span className="font-bold text-[15px]">
                  {seatsBooked || 500}+
                </span>{' '}
                Registered
              </p>
            </div>
          </div>
          {!hideCta && (
            <PrimaryButton
              py="2"
              onClick={handleClickEvent}
              ctaText={cardCTAContent()}
              className={`w-full px-2 text-center text-[16px] rounded-[4px] py-2 my-2 font-bold ${
                isBooked && !isStarted
                  ? 'text-lightgray-600 !bg-gray-1050 !border-0'
                  : isStarted && !isOfflineEvent
                  ? '!bg-orange-100 text-white !border-0'
                  : 'bg-primary text-white'
              }`}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default UpcomingEventCard
